import BaseState from "Engine/base/states/BaseState";
import animationTypes from '../animations/animationTypes';

export default class showWonJackpotGameState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    async start() {
        window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_JACKPOT_GAME);
        if (!PIXI.Assets.get(animationTypes.JACKPOT)) {
            OPPreloader.showDelayedPreloader();
            await PIXI.Assets.load(animationTypes.JACKPOT);
            OPPreloader.hideDelayedPreloader();
        }
        super.start();
    }

}
