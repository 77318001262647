import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class freespinsGameOutState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('freespinsEnd: onComplete', this.onCounterComplete, this)
  }

  onCounterComplete() {
    if(this.active) {
      this.complete();
      window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_REGULAR);
    }
  }
  start() {
    super.start();
    EntryPoint.GameModel.freespinsGameEnabled = false;
    EntryPoint.AutoPlaySettings.active = false;
  }
}
