import * as PIXI from 'pixi.js';
import BaseSlotReelsContainer from 'Engine/containers/BaseSlotReelsContainer';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eSoundType from '../sounds/eSoundType';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../animations/animationTypes';

export default class ReelsContainer extends BaseSlotReelsContainer {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.scattersStopCount = 0;

    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRoundedRect(-7,-5,1059,675, 60);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
    this.timeouts = [];
    this.hasScatter = false;
  }

  init() {
    // super.init();
    this.ControllerReels = this.getChildByName('controllerReels');
    this.ControllerReels.init(EntryPoint.configData.reels.availableReelsNormal);
    this.ControllerReels.onHittingBar = this.onHitBar.bind(this);
    this.ControllerReels.onAllReelsStopped = this.onReelsStopped.bind(this);
    this.antisipatorContainer = this.getChildByName('antisipator');
    // this.mask = this.getChildByName('slotMask');

    this.initAnimations();
  }

  async initAnimations() {
    const antisipatorAnimation = await animationCreator.createAnimationWithDelayedLoading(animationTypes.ANTISIPATOR);
    await animationCreator.uploadToGPU(antisipatorAnimation);
    this.antisipatorAnimation = antisipatorAnimation;
    this.antisipatorAnimation.state.setAnimation(0, 'antisipator', true);
    this.antisipatorAnimation.visible = false;
    this.antisipatorContainer.addChild(this.antisipatorAnimation)
  }

  addListeners() {
    //super.addListeners();
    GlobalDispatcher.add('reel:stopped', this.onReelStopped, this);
    GlobalDispatcher.add('stateManager:changed', this.onStateChanged, this);
    GlobalDispatcher.add('winningsController:slowWin', this.showWinningSymbols, this);
    // GlobalDispatcher.add('symbolAnimation:start', this.onSymbolAnimationStart, this);
    // GlobalDispatcher.add('symbolAnimation:complete', this.onSymbolAnimationComplete, this);
    // GlobalDispatcher.add('symbolAnimation:end', this.onSymbolAnimationComplete, this);
  }
  showWinningSymbols(data) {
    // this.hideWinningSymbols();
    data.params.path.forEach((pathData, index) => {
      if (pathData >= 0) {
        this.ControllerReels.reels[index].symbols[pathData].startAnimation();
      }
    });
  }

  onSymbolAnimationStart(event) {
    let position = event.params;
    this.ControllerReels.reels[position[0]].symbols[position[1] + 1].startAnimation();
  }

  onSymbolAnimationComplete(event) {
    let position = event.params;
    this.ControllerReels.reels[position[0]].symbols[position[1] + 1].stopAnimation();
  }

  start() {
    this.ControllerReels.start();
  }

  stop(data) {
    this.data = data;
    if (EntryPoint.GameSettings.quickStop) {
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.impactCoefficient = 0.25;
      this.ControllerReels.symbolsCountForStop = 3;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 1]
    } else {
      this._checkAntisipator(data)
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.symbolsCountForStop = this.isAntisipator ? [3, 8, 70] : [3, 8, 13];
      this.ControllerReels.impactCoefficient = 0.2;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 0.5]
    }
    this.ControllerReels.stop(this.data);
  }

  _checkAntisipator(data) {
    if((!EntryPoint.GameModel.isFreeSpinsMode || EntryPoint.GameModel.freespinsGameTriggered) && data[0].find(simbolId => simbolId === 9) && data[1].find(simbolId => simbolId === 9)){
      this.isAntisipator = true;
    }
  }

  startRespin() {
    let reel = this.reels[EntryPoint.GameModel.respinReelIndex];
    reel.speedIndex = 1;
    reel.start();
    this.reelsStarted++;
    // SoundManager.play(eSoundType.EST_REEL_MOVE, 0.7, true);
  }

  // stopRespin(data) {
  //   let reel = this.reels[EntryPoint.GameModel.respinReelIndex];
  //   //reel.stop(data[EntryPoint.GameModel.respinReelIndex]);
  //   let delay = NearWinCalculator.nearReels.indexOf(EntryPoint.GameModel.respinReelIndex) !== -1 ? 1200 : (EntryPoint.GameSettings.quickSpin ? 100 : 250);
  //   setTimeout(() => {
  //     reel.speedIndex = NearWinCalculator.nearReels.indexOf(EntryPoint.GameModel.respinReelIndex) !== -1 ? 5 : 1;
  //     const respinReelIndex = EntryPoint.GameModel.respinReelIndex;
  //     reel.stop(data[respinReelIndex], EntryPoint.GameModel.stopIndexes[respinReelIndex]);
  //   }, delay);
  // }

  onReelsStopped() {
    GlobalDispatcher.dispatch('reelsContainer:reelsStopped');
    if (this.isAntisipator) {
      this.isAntisipator = false;
      GlobalDispatcher.dispatch('ControllerSlot: jackpotterAnimationComplete')
    }
  }

  // onReelStopped(reelIndex) {
  //   const spacialSymbolIndex = EntryPoint.GameModel.winnings?.scatters?.length && EntryPoint.GameModel.winnings?.scatters[0]?.path[reelIndex]
  //   if (spacialSymbolIndex) {
  //     const symbols = this.ControllerReels.reels[reelIndex].symbols;
  //     symbols[spacialSymbolIndex].startStopAnimation();
  //   }
  // }

  onHitBar(reelIndex) {
    if (reelIndex === 1) this._tryStartAntisipator()
    if (reelIndex === 2) this._tryStopAntisipator()

    const spacialSymbolIndex = EntryPoint.GameModel.winnings?.jackpotters?.length && EntryPoint.GameModel.winnings?.jackpotters[0]?.path[reelIndex];
    if (spacialSymbolIndex) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      this.scattersStopCount++;
      this.hasScatter = true;
      symbols[spacialSymbolIndex].startStopAnimation();
    }
    if (EntryPoint.GameSettings.quickStop && reelIndex === 2) {
      SoundManager.play(eSoundType.EST_REEL_STOP, 0.5 + 0.2 * Math.random())
      if (this.hasScatter) {
        this.hasScatter = false;
        SoundManager.play(eSoundType.EST_SCATTER_STOP, 0.3 + 0.2 * Math.random())
      }
    } else if (!EntryPoint.GameSettings.quickStop) {
        SoundManager.play(eSoundType.EST_REEL_STOP, 0.5 + 0.2 * Math.random())
      if (this.hasScatter) {
        SoundManager.play(eSoundType.EST_SCATTER_STOP, 0.3 + 0.2 * Math.random())
        this.hasScatter = false;
      }
    }
  }

  _tryStartAntisipator() {
    if (this.isAntisipator) {
      GlobalDispatcher.dispatch('antisipator: start', this);
      SoundManager.play(eSoundType.EST_REEL_ANTISIPATOR, 0.6, false, 'winning');
      if (this.antisipatorAnimation) this.antisipatorAnimation.visible = true;
    }
  }

  _tryStopAntisipator() {
    if (this.isAntisipator) {
      GlobalDispatcher.dispatch('antisipator: stop', this);
      SoundManager.stop(eSoundType.EST_REEL_ANTISIPATOR);
      SoundManager.play(eSoundType.EST_REEL_ANTISIPATOR_TILE, 0.6, false, 'winning');
      if (this.antisipatorAnimation) this.antisipatorAnimation.visible = false;
    }
  }
}
