import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../animations/animationTypes';
import { gsap } from 'gsap';

export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.symbols = [];
    this.parents = [];
    this._coinsPull = [];
    this._coinsCount = 24;

    this.init()
    this.addListeners();
  }
  init() {
    this.chest = this.getChildByName('chest');
    this.landscapeLogo = this.getChildByName('logo');
    this.createCoinAnimations();
  }

  onGameResized(data) {
    if (data.isLandscape) {
      this.chest.x = this.chest.displayData.x;
      this.landscapeLogo.visible = true;
    } else if (data.isMobile && data.isPortrait) {
      this.chest.x = this.chest.displayData.x - 226;
      this.landscapeLogo.visible = false;
    }
  }

  addListeners() {
    GlobalDispatcher.add('animationSymbol: showStopAnimation', this.onStopAnimation, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );

  }
  onStopAnimation({params: symbol}) {
    this.parents.push(symbol.parent);
    const animationSymbolPosition = this.toLocal({ x: 0 , y: 0 }, symbol);
    symbol.x = animationSymbolPosition.x;
    symbol.y = animationSymbolPosition.y;
    symbol.animationSymbol.onStopAnimationComplete = this.onStopAnimationComplete.bind(this)
    this.addChild(symbol);
    this.symbols.push(symbol);
    this.startCoins(symbol);
  }
  onStopAnimationComplete(symbolAnimation) {
    symbolAnimation.onComplete = () => {
      if (this.symbols.length === (EntryPoint.GameModel.winnings?.jackpotters?.length && Number(EntryPoint.GameModel.winnings?.jackpotters[0]?.count))) {
        this.chest.update();
        GlobalDispatcher.dispatch('ControllerSlot: jackpotterAnimationComplete')
      }
    }
    symbolAnimation.start(false);
  }

  createCoinAnimations() {
    for (let i = 0; i < this._coinsCount; i++) {
      const coin = animationCreator.createAnimation(animationTypes.SYMBOLS);
      coin.state.setAnimation(0, 'coin_event', true);
      coin.state.update(Math.random());
      //toDo: optimization hack
      setTimeout(()=>coin.visible = false, 1);
      this._coinsPull.push(coin);
      this.addChildAt(coin, 4);
    }

  }

  startCoins(symbol) {
    const offset = 80;
    const startPosition = { x: symbol.x, y: symbol.y}
    const mediumPosition = { x: startPosition.x + (this.chest.x - symbol.x) / 2, y: startPosition.y + (this.chest.y - symbol.y) / 2 }
    const endPosition = { x: this.chest.x + 240, y: this.chest.y + 113 }
    for (let i = 0; i < this._coinsCount / 3; i++) {
      setTimeout(() => {
        const coin = this._coinsPull.pop();
        coin.visible = true;
        coin.x = startPosition.x;
        coin.y = startPosition.y;
        gsap.to(coin,
          {
            ease: 'Power1.easeInOut',
            duration: 1,
            motionPath: {
              path: [startPosition, mediumPosition, endPosition],
              alignOrigin: [0.5, 0.5],
            },
            onComplete: () => {
              coin.visible = false;
              this._coinsPull.push(coin);
            }
          });
      }, offset * i);
    }
  }

  onStateChanged({params: stateName}) {
    if (stateName === 'requestRollingState') {
      this.symbols.forEach((symbol, index) =>{
        const animationSymbolPosition = this.parents[index].toLocal({ x: 0 , y: 0 }, symbol);
        symbol.x = animationSymbolPosition.x;
        symbol.y = animationSymbolPosition.y;
        symbol.animationSymbol.onComplete = () => {};
        symbol.animationSymbol.onStopAnimationComplete = () => {};

        this.parents[index].addChild(symbol);
      })
      this.symbols = [];
      this.parents = [];
    }
  }
}
