import FlashLib from 'flashlib_onlyplay';

export default class SymbolsPool {
  static get pool() {
    if (!this._pool) {
      this._pool = [];
    }
    return this._pool;
  }

  static get extraPool() {
    if (!this._extraPool) {
      this._extraPool = [];
    }
    return this._extraPool;
  }

  static poolIn(item, extra) {
    let inItem = item;
    if (inItem === undefined) {
      inItem = FlashLib.createItemFromLibrary(extra ? 'movieClips/peppers/pepper' : 'movieClips/symbols/symbol', 'GameFlashLib');
    }
    if (inItem.parent) {
      inItem.parent.removeChild(inItem);
    }

    if (extra) {
      this.extraPool.push(inItem);
    } else {
      this.pool.push(inItem);
    }
  }

  static poolOut(id, reel, extra) {
    if (this.pool.length === 0 && !extra) {
      this.poolIn()
    }

    if (this.extraPool.length === 0 && extra) {
      this.poolIn(undefined, true)
    }

    let symbol;

    if (extra) {
      symbol = this.extraPool.shift();
      symbol.changeSymbol(id, reel);
    } else {
      symbol = this.pool.shift();
      symbol.changeSymbol(id, reel);
    }

    return symbol;
  }
}
