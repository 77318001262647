import BaseState from "Engine/base/states/BaseState";

export default class ShowWinningsState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    start() {
        super.start();
        this.complete();
    }
}
