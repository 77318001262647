import FlashLib from 'flashlib_onlyplay';
import { Linear, gsap } from 'gsap';
import SymbolsPool from './SymbolsPool';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { cloneArray, shuffle } from 'Engine/utils/array';

export default class BaseSlotReel extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.id = null;
    this.symbols = [];
    this.data = null;
    this.rollingTween = null;
    this.config = EntryPoint.configData;
    this.totalSymbolsCount = 5;
    this.speedIndex = 1;
    this.withBounce = true;

    this.removeChildren();
  }

  setData(id , data, extra) {
    this.id = id;
    this.data = data;
    this.symbolTopOffset = this.config.reels.symbolTopOffset || 0;
    this.isExtra = extra;
    const arrayToClone = this.isExtra ? 'availableReelsExtra' : 'availableReelsNormal';
    const reel = this.isExtra ? this.config.reels[arrayToClone][this.id - 1] : this.config.reels[arrayToClone][this.id - 1];
    this.possibleSymbols = shuffle(cloneArray(reel));
    this.offset = (this.isExtra ? this.config.extraSymbols.height : this.config.symbols.height) + this.symbolTopOffset;

    this._init();
    this._updateSymbolsPositions();
    this._createTweens();
  }

  start() {
    this.needStop = false;
    this.rollingTween.invalidate();
    this.rollingTween.play();
  }

  stop(data) {
    this.data = data.concat().reverse();
    this.needStop = true
  }

  startAnimationWinningSymbols(id) {
    let symbol = this.symbols[id + this.config.reels.topInvisibleSymbolsCount];
    symbol.displayFrame();
    symbol.startAnimation();
  }

  stopAnimationWinningSymbols() {
    this.symbols.forEach((symbol) => {
      symbol.stopAnimation();
    });
  }

  setActive(value) {
    this.symbols.forEach((symbol) => {
      symbol.setActive(value);
    });
  }

  _init() {
    this.symbolsInReel = this.isExtra ? this.config.reels.symbolsInExtraReel : this.config.reels.symbolsInReel;
    this.totalSymbolsCount = this.config.reels.topInvisibleSymbolsCount + this.symbolsInReel + this.config.reels.bottomInvisibleSymbolsCount;
    for (let i = 0; i < this.totalSymbolsCount; i++) {
      let symbol = SymbolsPool.poolOut(this.possibleSymbols[0], this.id, this.isExtra);
      this.addChild(symbol);
      this.symbols.push(symbol);
      this.possibleSymbols.unshift(this.possibleSymbols.pop());
    }
  }

  _updateSymbolsPositions() {
    this.symbols.forEach((symbol, index) => {
      symbol.y = (this.offset) * (index - 1);
    });
  }

  _createTweens() {
    if (this.rollingTween) this.rollingTween.kill();
    // if (this.startTween) this.startTween.kill();
    if (this.stopTween) this.stopTween.kill();

    // this.startTween = TweenMax.to(this.symbols, 0.2, { y: '-=' + (this.offset), yoyo: true, repeat: 1, paused: true, onComplete: () => {
    //     this.rollingTween.invalidate();
    //     this.rollingTween.restart();
    //   }});

    this.rollingTween = gsap.timeline({
      paused:true, repeat: -1,
      onRepeat: this._onRollingTweenRepeat, callbackScope: this,
    })

    this.rollingTween.to(this.symbols, {
      y: '+=' + (this.offset),
      duration: 0.07 / this.speedIndex,
      ease: Linear.easeNone,
    })
  }

  _onRollingTweenRepeat() {
    this.rollingTween.invalidate();
    if(!this.needStop) {
        this._cycleSymbols();
    } else {
      this.rollingTween.pause();
      this._cycleSymbols();
      this.symbols[0].changeSymbol(this.data[0])
      this._createStopTween();
    }
  }

  _cycleSymbols() {
    this.symbols.unshift(this.symbols.pop());
    this.symbols[0].changeSymbol(this.possibleSymbols[0]);
    this.symbols[0].y = (this.offset) * (-1);
    this.possibleSymbols.unshift(this.possibleSymbols.pop());
  }

  _createStopTween() {
    if (this.stopTween) this.stopTween.kill();

    this.stopTimeLine = gsap.timeline({paused: true});
    for (let i = 0; i < this.symbolsInReel; i++) {
      this.stopTimeLine
        .to(this.symbols, {
          y: '+=' + this.offset,
          duration: 1 / this.symbolsInReel,
          onComplete: () => {
            if (i + 1 < this.symbolsInReel) {
              this._cycleSymbols()
              this.symbols[0].changeSymbol(this.data[i + 1])
            }
          },
          callbackScope: this,
          ease: Linear.easeNone
        })
    }
    this.stopTimeLine
      .call(this._cycleSymbols.bind(this))
    // toDo: Bounce
    if (this.withBounce) {
      if (!this.isExtra) {
        this.stopTimeLine
          .to(this.symbols, {
            y: '+=' + this.offset / 8,
            duration:1 / this.symbolsInReel / 8,
            callbackScope: this,
            ease: Linear.easeNone,
          })
          .to(this.symbols, {
            y: '-=' + this.offset / 8,
            duration: 1 / this.symbolsInReel * 1.5,
            callbackScope: this,
            ease: Linear.easeNone
          })
      }
    }

    this.stopTimeLine.invalidate();

    this.stopTween = gsap.to(this.stopTimeLine, {
      time: this.stopTimeLine.duration(),
      duration: this.stopTimeLine.duration() / this.speedIndex,
      onStart: () => {
        if (!this.isExtra) GlobalDispatcher.dispatch('reel:willStop', { id: this.id, delay: this.stopTimeLine.duration() / this.speedIndex * 1000 });
      },
      onComplete: this._onStopTweenComplete,
      callbackScope: this,
    })

  }

  _onStopTweenComplete() {
    if (this.isExtra) {
      GlobalDispatcher.dispatch('extraReel:stopped', this.id);
    } else {
      GlobalDispatcher.dispatch('reel:stopped', this.id);
    }
  }
}
