import BaseState from "Engine/base/states/BaseState";
import animationTypes from '../animations/animationTypes';

export default class JackpotGameInState extends BaseState {
    async start() {
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        if (!PIXI.Assets.get(animationTypes.JACKPOT)) {
            OPPreloader.showDelayedPreloader();
            await PIXI.Assets.load(animationTypes.JACKPOT);
            OPPreloader.hideDelayedPreloader();
        }
        super.start();
        this.complete();
    }
}
