import GameModel from '../model/GameModel';
import DataProvider from './XXXData';
import XXXDataParser from './XXXDataParser';

export default new class ServerManager {
    constructor() {
        this.dataProvider = null;
        this.timeout = null;
        this.dataProvider = new DataProvider();
    }

    async onInit() {
        let request = {
            action: 'init'
        };
        const data = await this.dataProvider.sendInit(request);
        GameModel.setNewData(XXXDataParser.parseInit(data));
    }

    async onStartSpin() {
        let request = {
            betIndex: GameModel.betIndex,
            action: 'spin'
        };
        const data = await this.dataProvider.sendMessage(request, 'spin');
        GameModel.setNewData(XXXDataParser.parseSpin(data));
        return data;
    }

    async onJackpotPlaceOpen(position) {
        let request = {
            position,
        };
        const data = await this.dataProvider.sendMessage(request, 'jackpot_step');
        GameModel.setNewData(XXXDataParser.parseJackpotPlaceOpen(data));
        return data;
    }
}
