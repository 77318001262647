import BaseSlotSymbol from "Engine/containers/BaseSlotSymbol";

export default class Symbol extends BaseSlotSymbol {
    constructor(data, displayItemData) {
        super(data, displayItemData);
        this.animationSymbol = this.getChildByName('animationContainer');
        this.animation = null;
        this.incativeTintColor = 0x444444;
    }

    init() {
        this.animationSymbol = this.getChildByName('animationContainer');
        super.init();
    }

    createTweens() {

    }

    changeSymbol(id, reel) {
        super.changeSymbol(id, reel);
        this.stopAnimation();
        if (this.animationSymbol)  this.animationSymbol.id = id;
    }

    startAnimation() {
        this.image = this.getChildAt(0);
        this.image.visible = false;
        this.animationSymbol.start(false);
    }

    startStopAnimation() {
        this.image = this.getChildAt(0);
        this.image.visible = false;
        this.animationSymbol.startStopAnimation();
    }

    stopAnimation() {
        this.image.visible = true;
        if (this.animationSymbol) this.animationSymbol.stop();
    }


    setBlur(value) {
        // super.setBlur(value);
        // this.frame.setBlur();
        /*if (value && this.id < 6) {
           this.setActive(EntryPoint.GameModel.isFreeSpinsMode && this.id === EntryPoint.GameModel.freeSpins.specialSymbol);
        } else {
            this.setActive(true);
        }*/
        this.setActive(true);
    }

    setActive(value) {
        let image = this.getChildAt(1);
            image.tint = value ? 16777215 : this.incativeTintColor;
    }
}
