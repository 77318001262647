import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";

export default class BaseSlotReelsContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.reels = [];
        this.reelsStarted = 0;
        this.data = EntryPoint.GameModel.reels;

        this.init();
        this.addListeners();
    }

    init() {
        let configData = EntryPoint.configData;
        let reelsConfig = configData.reels;

        let reel = null;
        for(let i = 0; i < reelsConfig.reelsCount; i++) {
            reel = this.getChildByName('reel_' + i);
            reel.setData(i + 1, this.data[i]);
            this.reels.push(reel);
        }
    }

    addListeners() {
        GlobalDispatcher.add('reel:stopped', this.onReelStopped, this);
        GlobalDispatcher.add('stateManager:changed', this.onStateChanged, this);
        GlobalDispatcher.add('linesContainer:slowWinLine', this.showWinningSymbols, this);
    }

    start() {
        this.reels.forEach((reel) => {
            reel.withBounce = true;
            reel.speedIndex = 1;
            reel.start();
            this.reelsStarted++;
        });
    }

    stop(data) {
        this.data = data;
        let delay = 0;
        this.reels.forEach((reel, index, arr) => {
            delay += EntryPoint.NearWinCalculator.nearReels.indexOf(index) !== -1 ? 2000 : (EntryPoint.GameSettings.quickSpin ? 100 : 500);
            setTimeout(() => {
                reel.speedIndex = EntryPoint.NearWinCalculator.nearReels.indexOf(index) !== -1 ? 5 : 1;
                reel.stop(this.data[index]);
            }, delay);
        });
    }

    setActive(value) {
        this.reels.forEach((reel)=>{
            reel.setActive(value);
        })
    }

    showWinningSymbols(data) {
        this.hideWinningSymbols();
        data.params.path.forEach((pathData) => {
            this.reels[pathData[0]].startAnimationWinningSymbols(pathData[1]);
        });
    }

    hideWinningSymbols() {
        this.reels.forEach((reel)=>{
            reel.stopAnimationWinningSymbols();
        });
    }

    onReelStopped(event) {
        this.reelsStarted--;
        if (this.reelsStarted === 0) {
            GlobalDispatcher.dispatch('reelsContainer:reelsStopped');
        }
    }

    onStateChanged(data) {

    }
}
