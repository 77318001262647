import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";
import SpinTimeManager from "Engine/base/spinTime/SpinTimeManager";
import ServerManager from '../server/ServerManager';

export default class RequestRollingState extends BaseState {
    async start() {
        super.start();
        SpinTimeManager.start();
        EntryPoint.GameSettings.quickStop = false;
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
            OPWrapperService.freeBetsController.decreaseFreeBets()
            OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bet;
        }
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        await ServerManager.onStartSpin();
        if (this.active) {
            this.complete();
        }
    }

    stop() {
        EntryPoint.GameSettings.quickStop = true;
    }
}
