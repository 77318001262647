import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { goldFontStyle, winFontStyle } from '../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { shadowText } from 'Engine/utils/fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../sounds/eSoundType';
import { uiConfig } from '../ui/config';
import UiAdapter from '../ui/uiAdapterExtended';

export default class StartScreenContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.onKeyDown = this.onKeyDown.bind(this);

    this.init();
    this.addListeners();
  }


  onResize (data) {
    if ( data.isLandscape) {


      this.background.goToFrame(2)
      this.content.visible = true;
      this.portretContent.visible = false;
      this.startButton.pivot.x = 0;
      this.startButton.x = this.startButton.displayData.x;
      this.startButton.scale.set(this.startButton.displayData.scaleX);
      this.startButton.y = this.startButton.displayData.y;

    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1)
      this.content.visible = false;
      this.portretContent.visible = true;
      this.startButton.pivot.x = this.startButton.displayData.width / 2;
      this.startButton.x = this.startButton.displayData.x  + this.startButton.displayData.width / 2;
      this.startButton.scale.set(1.4);
      this.startButton.y = this.startButton.displayData.y + 260;
    }
  }

  init() {
    this.content = this.getChildByName('content');
    this.portretContent = this.getChildByName('portretContent');
    this.background = this.getChildByName('background');
    this.visible = false;
    this.interactive = true;
    this.startButton = this.getChildByName('startButton');
    makeHitArea(this.startButton);
    this.setTextsStyle();
    this.setWinAmounts();

  }

  addListeners() {
    GlobalDispatcher.addOnce('startScreen:show', this.show.bind(this));
    this.startButton.on('pointertap', this.hide, this);
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
  }

  setTextsStyle() {
    const setFunction = (content) => {
      for (let i = 0; i < 10; i++) {
        const text = content.getChildByName(`start_screen_${i}`);
        text.style = { ...text.style, ...goldFontStyle };
          if (i === 9) text.text = window.OPWrapperService.localizations.getLocalizedText('start_screen_7');
      }
      const freespinsCount = content.getChildByName(`start_screen_freespins_count`);
      freespinsCount.style = { ...freespinsCount.style, ...goldFontStyle };
      const winAmount = content.getChildByName(`start_screen_max_win_amount`);
      winAmount.style = { ...winAmount.style, ...goldFontStyle };
    }
    const startButtonText = this.startButton.getChildByName('start_screen_play');
    startButtonText.style = { ...startButtonText.style, ...shadowText };
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);

  }

  setWinAmounts() {
    const setFunction = (content) => {
      const maxWinField = content.getChildByName(`start_screen_max_win_amount`)
      const wildCoefs = EntryPoint.GameModel.paytableCurrentInfo['wildField'];
      const maxBet = EntryPoint.GameModel.betPossible[EntryPoint.GameModel.betPossible.length - 1];
      const decimal = EntryPoint.GameModel.currencyInfo.decimals;
      maxWinField.text = MoneyFormat.formatCurrency(wildCoefs * maxBet, decimal);
      maxWinField.fitSize();
    }
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);
  }

  onKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.hide();
    }
  }

  show() {
    document.addEventListener('keydown', this.onKeyDown);
    this.startButton.enabled = true;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.startButton.enabled = false;
    GlobalDispatcher.dispatch('startScreen:hide');
    SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
    document.removeEventListener('keydown', this.onKeyDown);
    OPWrapperService.freeBetsController.show();

    const ControllerUi = window.OPWrapperService.initUi(uiConfig);
    new UiAdapter(ControllerUi).init({
      clickSound: { soundName: eSoundType.EST_BUTTON_CLICK, volume: 0.7, group: 'default' }
    });
  }
}
