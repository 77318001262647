import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import eServerClientStone from './eServerClientStone';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import ServerManager from '../../server/ServerManager';

export default class ControllerJackpotGameField extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this._queue = [];
    this.addListeners();
  }
  init(panels) {
    this.plates = [];
    this.col = [];
    for (let i = 0; i < 5; i++) {
      const colMovieClip = this.getChildByName(`col_${i}`);
      const col = []
      this.col[i] = colMovieClip;
      for (let j = 0; j < 3; j++) {
       const plate =  colMovieClip.getChildByName(`element_${j}`);
        plate.colId = i;
        plate.id = j;
        plate.panels = panels;
        col.push(plate);
      }
      this.plates.push(col);
    }
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
    GlobalDispatcher.add('plate: startAnimationMove', this.onStartAnimationMove, this);
    GlobalDispatcher.add('jackpotGame: addOpeningPlateToQueue', this.addOpeningPlateToQueue, this);
  }

  onResize (data) {
    if (data.isLandscape) {
      this.anchorDefault();
      this.y = this.displayData.y
      this.scale.set(this.displayData.scaleX);
    } else if (data.isMobile && data.isPortrait) {
      this.centring();
      this.y = this.displayData.y + this.displayData.height / 2 + 60;
      this.scale.set(0.84);
    }
  }

  addOpeningPlateToQueue({ params: plateParams }) {
    this._queue.push(plateParams);
    this.tryOpenPlates();
  }

  tryOpenPlates() {
    if (this._opens) return;
    this._opens = true;
    this.openNextPlate();
  }

  openNextPlate() {
    if (this._queue.length === 0 || !EntryPoint.GameModel.isJackpotGame) {
      this._queue.forEach(({ stopShakeFunc }) => stopShakeFunc());
      this._queue = [];
      this._opens = false;
      return;
    }
    const { position, openFunc, stopShakeFunc } = this._queue.shift();
    ServerManager.onJackpotPlaceOpen(position)
      .then(openFunc)
      .catch(stopShakeFunc)
      .finally(this.openNextPlate.bind(this))
    ;
  }

  create() {
    EntryPoint.GameModel.jackpotField.forEach((col, i) => {
      col.forEach((stoneIndex, j) => {
        if (stoneIndex >= 0) {
          this.plates[i][j].openByType(eServerClientStone[stoneIndex]);
        } else {
          this.plates[i][j].closePlate()
        }
      })
    })
  }

  onStartAnimationMove({ params: { stone, microIcon, stoneIndex } }) {
    const prevParent = stone.parent;
    const stonePosition = this.toLocal({ x: 0 , y: 0 }, stone);
    stone.x = stonePosition.x;
    stone.y = stonePosition.y;
    this.addChild(stone);

    const p1 ={
      x:  0,
      y:  400
    }

    microIcon.displayObjectUpdateTransform();
    const scaleCorrecting = 0.8;
    const worldScaleCorrecting = microIcon.worldTransform.a / stone.worldTransform.a;
    const yEndOffset = -2;

    const endPosition = stone.toLocal({ x: microIcon.displayData.width * (1 - scaleCorrecting) / 2 , y: microIcon.displayData.height * (1 - scaleCorrecting) / 2 + yEndOffset }, microIcon);

    const bezierConfig = {
      p0: { x: 0, y: 0 },
      p1: p1,
      p2: endPosition,
      duration: 1000
    };

    stone.to(
      {bezierConfig, width: microIcon.displayData.width * scaleCorrecting * worldScaleCorrecting, height: microIcon.displayData.height * scaleCorrecting * worldScaleCorrecting },
      () => {
        setTimeout(() => {
          prevParent.addChild(stone);
          stone.x = stone.displayData.x;
          stone.y = stone.displayData.y;
        }, 3000)
        GlobalDispatcher.dispatch('plate: openComplete', stoneIndex)
      }
    )
  }
}
