import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';

export default class ShowStartScreenState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  start() {
    super.start();
    window.OPWrapperService.infoPanel.setGameState(
      EntryPoint.GameModel.isJackpotGame
        ? window.OPWrapperService.infoPanel.gameStateTypes.EGST_JACKPOT_GAME
        : EntryPoint.GameModel.isFreeSpinsMode
          ? window.OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME
          : window.OPWrapperService.infoPanel.gameStateTypes.EGST_REGULAR
    );
    if (EntryPoint.GameModel.isFreeSpinsMode) {
      EntryPoint.GameModel.freespinsGameEnabled = true;
    }
    GlobalDispatcher.dispatch('startScreen:show');
    GlobalDispatcher.add('startScreen:hide', this.stop.bind(this));
  }

  stop() {
    if (!this.active) return;
    this.complete();
  }
}
