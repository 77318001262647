export default class AdaptiveBitmapText extends PIXI.BitmapText {
  constructor(text, style) {
    super(text, style);
    this._origFontSize = style.fontSize;
    this.alignY = style.alignY;
  }

  setAdaptiveBox(width, height) {
    this._isAdaptive = width || height;
    this._isAdaptiveWidth = !!width;
    this._isAdaptiveHeight = !!height;
    this.textRect = new PIXI.Rectangle(0, 0, width || 0, height || 0);
    this.fitSize();
  }

  drawDebug() {
    if (this._origRectGraphic) this._origRectGraphic.destroy();
    let origRect = new PIXI.Graphics();
    origRect.lineStyle(1, 0xFF0000, 1);
    origRect.drawRect(this.position.x - this.transform.position.x - this.origWidth * this.anchor.x, this.position.y - this.transform.position.y - this.origHeight * this.anchor.y, this.origWidth, this.origHeight);
    this._origRectGraphic = origRect;
    this.addChild(origRect);

    if (this._realRectGraphic) this._realRectGraphic.destroy();
    let realRect = new PIXI.Graphics();
    realRect.lineStyle(1, 0x00FF00, 1);
    realRect.drawRect((this.textRect.x - this.transform.position.x - this.width * this.anchor.x), (this.textRect.y - this.transform.position.y - this.height * this.anchor.y), this.width, this.height);
    realRect.endFill();
    this._realRectGraphic = realRect;
    this.addChild(realRect);
  }

  fitSize() {
    this.fontSize = this._origFontSize;
    this.updateText(true);

    let doFitWidth = this._isAdaptiveWidth;
    let doFitHeight = this._isAdaptiveHeight;
    while (doFitWidth || doFitHeight) {
      this.fontSize--;
      this.updateText(true);
      if (doFitWidth) {
        doFitWidth = this.origWidth > this.width;
      }
      if (doFitHeight) {
        doFitHeight = this.origHeight > this.height;
      }
    }
    // this.correctPosition();
  }

  updateText () {
    switch (this.align) {
      case 'center':
        this._anchor._x = 0.5;
        break;
      case 'right':
        this._anchor._x = 1;
        break;
      default:
        this._anchor._x = 0;
        break;
    }
    switch (this.alignY) {
      case 'center':
        this._anchor._y = 0.5;
        break;
      case 'right':
        this._anchor._y = 1;
        break;
      default:
        this._anchor._y = 0;
        break;
    }
    super.updateText();
  }

  set text(value) {
    super.text = value;
    if (this._isAdaptive) this.fitSize();
  }

  set origFontSize(fontSize) {
    this._origFontSize = fontSize;
    if (this._isAdaptive) this.fitSize();
  }
  get origWidth() {
    return this.textWidth;
  }

  set origWidth(value) {
    this.textWidth = value;
  }

  get origHeight() {
    return this.textHeight;
  }

  set origHeight(value) {
    this.textHeight = value;
  }

  get width() {
    return this._isAdaptiveWidth ? this.textRect.width : this.origWidth;
  }

  set width(value) {
    if (this._isAdaptiveWidth) {
      this.textRect.width = value;
      // this.correctPosition();
    } else {
      this.origWidth = value;
    }
  }

  get height() {
    return this._isAdaptiveHeight ? this.textRect.height : this.origHeight;
  }

  set height(value) {
    if (this._isAdaptiveHeight) {
      this.textRect.height = value;
      // this.correctPosition();
    } else {
      this.origHeight = value;
    }
  }
}
