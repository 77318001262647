import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class freespinsGameInState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('freespinsStart: onComplete', this.onCounterComplete, this)
  }

  onCounterComplete() {
      if(this.active) {
        this.complete();
      }
  }
  start() {
    super.start();
    EntryPoint.AutoPlaySettings.active = false;
    EntryPoint.GameModel.freespinsGameEnabled = true;
    window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
  }
}
