import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";

export default class ShowWinState extends BaseState {
    constructor(stateData) {
        super(stateData);

        this.timeout = null;
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('winningsController:allWinningsComplete', this.tryStop, this);
        GlobalDispatcher.add('bigWinContainer:stopState', this.tryStop, this);
    }

    tryStop() {
        if (this.active) {
            if (this.canStop) {
                this.stop();
            } else {
                this.canStop = true;
            }
        }
    }

    onEnd() {
        super.onEnd();
        this.canStop = false;
    }
}
