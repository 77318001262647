import BaseState from "Engine/base/states/BaseState";
import EntryPoint from '../libs/game-engine/src/EntryPoint';

export default class JackpotGameOutState extends BaseState {
    start() {
        super.start();
        if (this.active) {
            window.OPWrapperService.infoPanel.setGameState(
              EntryPoint.GameModel.freespinsGameEnabled
                ? window.OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME
                : window.OPWrapperService.infoPanel.gameStateTypes.EGST_REGULAR
            );
            this.complete();
        }
    }
}
