import * as PIXI from 'pixi.js'
import './utils/basePixiExtention';
import 'Engine/utils/spineExtantion';
import PreloaderController from './base/preloader/BasePreloaderController';
import GlobalDispatcher from "./events/GlobalDispatcher";
import { Linear, TweenMax, gsap } from 'gsap';
import LocalizeTextField from "Engine/base/localization/LocalizeTextField";
import FlashLib from 'flashlib_onlyplay';
import DropConfig from "./DropConfig";

export default class BaseGame {
    constructor(preloaderConfig) {
        FlashLib.TextField = LocalizeTextField;

        this.preloaderController = null;
        this.preloaderView = null;
        this._preloaderConfig = preloaderConfig;

        this.GPUProgressUpdater = this.GPUProgressUpdater.bind(this);
        this.removeGPUProgressUpdater = this.removeGPUProgressUpdater.bind(this);

        this.init();
        this.addListeners()
    }

    addListeners() {
        GlobalDispatcher.add('preloaderController:progress', this.onPreloaderProgress, this);
    }

    init() {
        this.initStartParams();
        this.initStartClasses();
        this.createGame();
        this.createPreloader();
    }

    initStartClasses() {
        //this.serverManager = new SlotServerManager();
        //StatesManager.setStatesConfig(StatesConfig);
        //GameModel.init();
    }

    linkToTweenMax() {
        if(gsap) {
            this.app.ticker.stop();
            gsap.ticker.add(() => {
                this.app.ticker.update();
            });
        } else {
            this.app.ticker.stop();
            TweenMax.ticker.addEventListener('tick', () => {
                this.app.ticker.update();
            });
        }
    }

    linkToPixi() {
        if (gsap) {
            gsap.ticker.sleep();
            this.app.ticker.add(()=>{
                try {
                    gsap.ticker.tick();
                } catch (e) {
                    console.error(e);
                    console.error('gsap ticker fall down');
                }
            });
        } else {
            TweenMax.ticker.sleep();
            this.app.ticker.add(()=>{
                TweenMax.ticker.tick();
            });
        }
    }

    initStartParams() {
        this.gameSize = {
            width: 960*4,
            height: 536*4
        };
        this.baseSize = {
            width: window.innerWidth,
            height: window.innerHeight,
            offsetY: 0,
            ratio: 960 / 536
        };
        this.scaleData = {
            type: 'css',
            app: this.app,
            safeWidth: 960*4,
            safeHeight: 536*4,
            fullSize: true,
            gameSize: this.gameSize
        };
    }

    createGame(applicationOptions = {}) {
        this.container = document.getElementById('container');
        //PIXI.utils.skipHello();
        this.applicationOptions = {
            /*antialias: true,
            forceFXAA: true,*/
            //forceCanvas: true,
            backgroundColor: 0x0,
            powerPreference: 'high-performance',
            //backgroundColor: 0x4597B7,
            //legacy: true,
           // width: ScaleManager.currentSizes.width,
            //height: ScaleManager.currentSizes.width / ScaleManager.currentSizes.graphicsRatio
            resolution: window.devicePixelRatio < 2 ? window.devicePixelRatio : 2,
          // width:Config.CANVAS_WIDTH_LANDSCAPE,
          // height:Config.CANVAS_HEIGHT_LANDSCAPE
            ...applicationOptions
        };
        this.app = new PIXI.Application(this.applicationOptions);
        this.app.view.id = 'gameCanvas';
        this.app.view.addEventListener('webglcontextlost', (event) => {
            window.OPUtility.messageWindow.showError(window.OPUtility.errorTypes.TO_MANY_ACTIVE_WEBGL_CONTEXTS.CODE);
        });
        this.container.appendChild(this.app.view);


        this.app.view.addEventListener('touchend', (e) => { //hack for pixi 7.4.2
            e.preventDefault();
            e.stopPropagation();
        }, true);
        this.scaleData.app = this.app;
        DropConfig.init();
    }

    createPreloader() {
        this.startingProgressValueForGPUDownloading = 90;
        if (!this._preloaderConfig) throw new Error('Not found "PreloaderConfig". Please set config as constructor argument');
        this.preloaderController = new PreloaderController(this._preloaderConfig);
        this.preloaderController.on('gameLoadingComplete', this.onLoadingComplete, this);
        this.preloaderView = document.getElementById('preloader');
        const prepare =  this.app.renderer.prepare;
        prepare.uploadHooks.unshift(this.GPUProgressUpdater);
        prepare.completes.push(this.removeGPUProgressUpdater);
    }

    onPreloaderProgress(event) {
        window.OPPreloader.setProgress(event.params * this.startingProgressValueForGPUDownloading / 100);
    }

    GPUProgressUpdater(renderer, item) {
        const queue = this.app.renderer.prepare.queue;
            if (!this.notFirstCall) {
                this.queueStartingLength = queue.length;
                this.notFirstCall = true;
            }
            const progress = 1 - (queue.length - 1) / this.queueStartingLength;
            const viewProgress = this.startingProgressValueForGPUDownloading + progress * (100 - this.startingProgressValueForGPUDownloading);
            window.OPPreloader.setProgress(viewProgress);
            return false;
    }

    removeGPUProgressUpdater() {
        const prepare = this.app.renderer.prepare;
        const GPUHookIndex = prepare.uploadHooks.findIndex(hook => hook === this.GPUProgressUpdater);
        if (GPUHookIndex !== -1) {
            prepare.uploadHooks.splice(GPUHookIndex, 1);
        }
    }

    hidePreloader() {
        return window.OPPreloader.hide({withFade: true});
    }

    onLoadingComplete() {
            this.constructGame();
            window.OPWrapperService.ScaleManager.init(this.scaleData);
    }

    constructGame() {
        //this.main = new Main();
        this.app.stage.addChildAt(this.main, 0);
        GlobalDispatcher.dispatch('game:gameStarted');
        this.linkToTweenMax();
    }
}
