import FlashLib from 'flashlib_onlyplay';
import animationTypes from '../animations/animationTypes';
import animationCreator from 'Engine/animations/animationCreator';

export default class ControllerPopupsBgAnimation extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this._initAnimationPromise = this.init();
  }
  async init() {
    this.animation = await animationCreator.createAnimationWithDelayedLoading(animationTypes.BIG_WIN);
    this.animation.visible = false;
    this.addChild(this.animation);
  }
  async start() {
    if (!this.animation) {
      OPPreloader.showDelayedPreloader();
      await this._initAnimationPromise
      OPPreloader.hideDelayedPreloader();
    }
    this.animation.visible = true;
    this.animation.state.setAnimation(0, 'sample_pop-up', true)
  }
  stop() {
    this.animation.visible = false;
  }
}
