import FlashLib from 'flashlib_onlyplay';
import { wonJackpotGameFontStyle } from '../../fontStyles';
import { gsap } from 'gsap';
import StatesManager from 'Engine/base/states/StatesManager';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';

export default class PopupWonJackpotGame extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.visible = false
    this.alpha = 0;
    this.interactive = true;
    this.startButton = this.getChildByName('startButton')
    this.startButton.enabled = true;
    this.setTextsStyle()
    this.addActions();
  }

  setTextsStyle() {
    const textJackpoGame = this.getChildByName('popup_won_jackpot_game_jacpot_game');
    textJackpoGame.style = { ...textJackpoGame.style, ...wonJackpotGameFontStyle }
    const textStart = this.startButton.getChildByName('popup_won_jackpot_game_button_start')
    textStart.style = {
      ...textStart.style,
      dropShadow: true,
      dropShadowAlpha: 0.82,
      dropShadowAngle: 1.5,
      dropShadowBlur: 10,
      dropShadowDistance: 6,}
  }

  addActions() {
    this.startButton.on('pointertap', this.onButtonClick, this);
  }

  onButtonClick() {
    StatesManager.goToNextState();
    this.startButton.enabled = false;
  }

  show() {
    this.visible = true;
    this.startButton.enabled = true;
    gsap.to(this, { alpha: 1, duration: 0.6});
    SoundManager.play(eSoundType.EST_WIN_JACKPOT_GAME, 0.7, false, 'winning');
  }

  hide(){
    gsap.to(this, { alpha: 0, duration: 0.6, onComplete: () => {
        this.visible = false
      }});
  }
}
