import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../animations/animationTypes';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class ControllerChest extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.eChestStates = [
      'st_1',
      'st_2',
      'st_3',
      'st_4',
      'st_5',
    ];
    this._initAnimation();
    this._addListeners();
    this.update();
  }

  _addListeners() {
    GlobalDispatcher.add('model:betChanged', this.update, this)
    GlobalDispatcher.add('stateStart:jackpotGameInState', this.update, this);

  }

  _initAnimation() {
    this.animation = animationCreator.createAnimation(animationTypes.CHEST);
    this.getChildByName('animationContainer').addChild(this.animation);
  }

  update() {
    const progress = EntryPoint.GameModel.progressToJackpot;
    const index = progress === 0 ? 0 : progress >= 500 ? 4 :  Math.floor(progress / (500 / 4) + 1);
    if (this.currentIndex === index) return;
    this.currentIndex = index
    this.animation.state.setAnimation(0,this.eChestStates[this.currentIndex], true);
  }
}
