import GameModel from '../model/GameModel';
import InitState from 'Engine/states/InitState';
import IdleState from 'Engine/states/IdleState';
import RequestRollingState from './RequestRollingState';
import StopRollingState from './StopRollingState';
import ShowWinningsState from './ShowWinningsState';
import ShowWinState from './ShowWinState';
import ShowLinesWinState from './ShowLinesWinState';
import MinimalRollingState from './MinimalRollingState';
import EntryPoint from 'Engine/EntryPoint';
import ShowStartScreenState from './showStartScreenState';
import JackpotGameInState from './JackpotGameInState';
import JackpotGameIdleState from './JackpotGameIdleState';
import JackpotGameOutState from './JackpotGameOutState';
import ShowWonJackpotGameState from './ShowWonJackpotGameState';
import StopWinState from './StopWinState';
import freespinsGameOutState from './freespinsGameOutState';
import freespinsGameInState from './freespinsGameInState';
import WaitUserInteractionState from './WaitUserInteractionState';
import AnimationJackpotterState from './AnimationJackpotterState';

export const StatesConfig = [
  {
    name: 'initState', class: InitState, transitions: [
      {
        to: 'showStartScreenState', condition: function () {
          return true;
        }
      }
    ], entry: true
  },
  {
    name: 'idleState', class: IdleState, transitions: [
      {
        to: 'requestRollingState', condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: 'requestRollingState', class: RequestRollingState, transitions: [
      {
        to: 'minimalRollingState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'minimalRollingState', class: MinimalRollingState, transitions: [
      {
        to: 'stopRollingState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showWonJackpotGameState', class: ShowWonJackpotGameState, transitions: [
      {
        to: 'jackpotGameInState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'jackpotGameInState', class: JackpotGameInState, transitions: [
      {
        to: 'jackpotGameIdleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'jackpotGameIdleState', class: JackpotGameIdleState, transitions: [
      {
        to: 'jackpotGameOutState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'jackpotGameOutState', class: JackpotGameOutState, transitions: [
      {
        to: 'freespinsGameOutState', condition: function () {

          return EntryPoint.GameModel.freespinsGameEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: 'freespinsGameInState', condition: function () {
          return EntryPoint.GameModel.freespinsGameTriggered ;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'stopRollingState', class: StopRollingState, transitions: [
      {
        to: 'animationJackpotterState', condition: function () {
          return EntryPoint.GameModel.winnings?.jackpotters?.length && !(GameModel.winnings.lines && GameModel.winnings.lines.length > 0) && (EntryPoint.AutoPlaySettings.active || EntryPoint.GameModel.isFreeSpinsMode);
        }
      },
      {
        to: 'showLinesWinState', condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: 'showWonJackpotGameState', condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: 'freespinsGameOutState', condition: function () {

          return EntryPoint.GameModel.freespinsGameEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: 'freespinsGameInState', condition: function () {
          return EntryPoint.GameModel.freespinsGameTriggered ;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showLinesWinState', class: ShowLinesWinState, transitions: [
      {
        to: 'showWinningsState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showWinState', class: ShowWinState, transitions: [
      {
        to: 'stopWinState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'stopWinState', class: StopWinState, transitions: [
      {
        to: 'showWonJackpotGameState', condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: 'freespinsGameOutState', condition: function () {

          return EntryPoint.GameModel.freespinsGameEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: 'freespinsGameInState', condition: function () {
          return EntryPoint.GameModel.freespinsGameTriggered ;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showWinningsState', class: ShowWinningsState, transitions: [
      {
        to: 'showWinState', condition: function () {
          return !!GameModel.totalWin;
        }
      },
      {
        to: 'showWonJackpotGameState', condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: 'freespinsGameOutState', condition: function () {

          return EntryPoint.GameModel.freespinsGameEnabled && EntryPoint.GameModel.freespins <= 0;
        }
      },
      {
        to: 'freespinsGameInState', condition: function () {
          return EntryPoint.GameModel.freespinsGameTriggered ;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },

  {
    name: 'showStartScreenState', class: ShowStartScreenState, transitions: [
      {
        to: 'jackpotGameInState', condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: 'waitUserInteractionState', condition: function () {
          return EntryPoint.GameModel.isFreeSpinsMode;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'freespinsGameInState', class: freespinsGameInState, transitions: [
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'freespinsGameOutState', class: freespinsGameOutState, transitions: [
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'waitUserInteractionState', class: WaitUserInteractionState, transitions: [
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'animationJackpotterState', class: AnimationJackpotterState, transitions: [
      {
        to: 'showLinesWinState', condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: 'showWonJackpotGameState', condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: 'freespinsGameOutState', condition: function () {

          return EntryPoint.GameModel.freespinsGameEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: 'freespinsGameInState', condition: function () {
          return EntryPoint.GameModel.freespinsGameTriggered ;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  }
];
