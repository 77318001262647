import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class UiAdapterExtended extends UiAdapter {

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('stateEnd:freespinsGameInState', this.showUi, this);
    GlobalDispatcher.add('stateEnd:freespinsGameOutState', this.showUi, this);
  }


  extraStateHandlers = {
    'showWonJackpotGameState': () => {
      this.hideUi();
    },
    'jackpotGameIdleState': () => {
      this.hideUi();
    },
    'jackpotGameOutState': () => {
      this.showUi();
      this.ControllerUi.setWin(EntryPoint.GameModel.totalWin + EntryPoint.GameModel.jackpotWinAmount);
    },
    'freespinsGameInState': () => {
      this.hideUi();
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
      this.ControllerUi.toggleSpinButton(false);

      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
      this.ControllerUi.showFreeSpins(EntryPoint.GameModel.freespins, this._bonusLabelText);
      this.ControllerUi.toggleInfoButton(false);
      this.ControllerUi.toggleTournamentsLabel(false);
      this.ControllerUi.showTotalWin();
    },
    'freespinsGameOutState': () => {
      this.hideUi();
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
      this.ControllerUi.toggleSpinButton(false);

      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
      this.ControllerUi.toggleInfoButton(false);
      this.ControllerUi.toggleTournamentsLabel(false);
      this.ControllerUi.setTotalWin(EntryPoint.GameModel.freespinsTotalWin);
      this.ControllerUi.hideFreeSpins();
      this.ControllerUi.hideTotalWin();
    },
    'showWinningsState': () => {
      this.ControllerUi.toggleInfoButton(false);
    },
    'animationJackpotterState': () => {
      if (EntryPoint.GameModel.freespinsEnabled) this.ControllerUi.showSpinButton();
      this.ControllerUi.toggleInfoButton(false);
    },
    default: () => {
    },
  };
}
