import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../../animations/animationTypes';

export default class ControllerJackpotPanel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this._initAnimation();
  }

  _initAnimation() {
    this.animationContainer = this.getChildByName('animation');
    this.animation = animationCreator.createAnimation(animationTypes.JACKPOT_PANEL)
    this.animation.state.addListener({
      complete: () => {
        this.animation.visible = false;
      }
    })
    this.animation.visible = false;
    this.animationContainer.addChild(this.animation);
  }

  startAnimation() {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, `${this.type}`, false);
  }
}
