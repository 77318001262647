import { goldFontStyle } from '../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import { gsap } from 'gsap/all';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';

export default class ControllerFreespinsStart extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init()
  }
  init() {
    super.init();
    this.youWinText = this.getChildByName('freespins_start_you_win');
    this.freespinsText = this.getChildByName('freespins_start_freespins');
    this.textFieldfreespinsAmount = this.getChildByName('amount');
    this.setTextStile()
  }
  setTextStile() {
    this.youWinText.style = { ...this.youWinText.style, ...goldFontStyle }
    this.freespinsText.style = { ...this.freespinsText.style, ...goldFontStyle }
    this.textFieldfreespinsAmount.style = { ...this.textFieldfreespinsAmount.style, ...goldFontStyle }
  }

  onShow() {
    super.onShow();
    this.freespinsCount = 0;
    gsap.to(this,  { freespinsCount: EntryPoint.GameModel.freespinsGiven, duration: 2, onComplete: () => {
      setTimeout(() => {
          this.hide();
        }
      ,1000)
      }})
    SoundManager.play(eSoundType.EST_FREESPINS_WIN, 0.5,false, 'winning');
  }
  onHide() {
    super.onHide();
    GlobalDispatcher.dispatch('freespinsStart: onComplete')
  }

  set freespinsCount(value) {
    this._freespinsCount = value.toFixed(0)
    this.textFieldfreespinsAmount.text = this._freespinsCount;
  }
  get freespinsCount() {
    return this._freespinsCount;
  }
}
