import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import { makeHitArea } from 'Engine/utils/makeHitArea';

export default class PaytableControls extends FlashLib.MovieClip{
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addActions();

    this.interactive = true;
    this.range = null;
    this.currentPage = 1;
  }

  init() {
    this.btnLeft = this.getChildByName('btnLeft');
    this.btnRight = this.getChildByName('btnRight');
    this.btnBack = this.getChildByName('btnBack');
    makeHitArea(this.btnLeft);
    makeHitArea(this.btnRight);
    makeHitArea(this.btnBack);
    this.btnBack.enabled = true;
  }

  addActions() {
    this.btnLeft.on('pointertap', this.onLeftClick, this);
    this.btnRight.on('pointertap', this.onRightClick, this);
    this.btnBack.on('pointertap', this.onBackClick, this);
  }

  checkButtonsEnabled() {
    this.btnLeft.enabled = this.range.indexOf(this.currentPage) > 0;
    this.btnRight.enabled = this.range.indexOf(this.currentPage) < this.range.length - 1;
  }

  onBackClick() {
    GlobalDispatcher.dispatch('paytable:backClick');
    this.reset();
  }

  onLeftClick() {
    this.currentPage--;
    GlobalDispatcher.dispatch('paytable:changePage', this.currentPage);
    this.checkButtonsEnabled();
  }

  onRightClick() {
    this.currentPage++;
    GlobalDispatcher.dispatch('paytable:changePage', this.currentPage);
    this.checkButtonsEnabled();
  }

  setRange(array) {
    this.range = array;
    this.checkButtonsEnabled();
  }

  reset() {
    this.currentPage = 1;
    this.checkButtonsEnabled();
  }
}
