import FlashLib from 'flashlib_onlyplay';
import { goldFontStyle, goldFontWithoutStokesStyle } from '../fontStyles';
import BaseMoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class TotalWinContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
  }
  init() {
    this.lableField = this.getChildByName('lable');
    this.amountField = this.getChildByName('amount');
    this.setTextStyles();
  }
  setTextStyles() {
    this.lableField.style = { ...this.lableField.style, ...goldFontStyle }
    this.amountField.style = { ...this.amountField.style, ...goldFontWithoutStokesStyle }
  }
  setValue(value) {
    this.amountField.text = BaseMoneyFormat.format(value,true );
  }

}
