export default {
  EST_START_SCREEN: 'showStartScreenState',
  EST_IDLE: 'idleState',
  EST_BUY_BONUS: 'buyBonusState',
  EST_FREE_SPINS_IN: 'FreespinsInState',
  EST_FREE_SPINS_OUT: 'FreespinsOutState',
  EST_INIT: 'initState',
  EST_REQUEST_ROLLING: 'requestRollingState',
  EST_MINIMAL_ROLLING: 'minimalRollingState',
  EST_STOP_ROLLING: 'stopRollingState',
  EST_SHOW_WIN: 'showWinState',
  EST_STOP_WIN: 'stopWinState',
  EST_WAIT_USER_INTERACTION: 'waitUserInteractionState',
}
