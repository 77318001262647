import FlashLib from 'flashlib_onlyplay';

import BaseCheckBox from "Engine/base/ui/BaseCheckBox";

import Main from "./Main";

import Reel from "./reels/BaseSlotReel";
import ReelsContainer from "./reels/ReelsContainer";
import OverlayContainer from "Engine/containers/overlay/BaseOverlayContainer";

import Symbol from "./symbols/Symbol";

// import SlotLinesContainer from "./lines/SlotLinesContainer";
// import WinLineAnimated from "./lines/WinLineAnimated";


import SettingsContainer from "Engine/containers/setting/BaseSettingsContainer";

import StartScreenContainer from './startScreen/startScreen';

import InfoPopupContainer from "Engine/containers/InfoPopupContainer";


import PaytableContainer from "./paytable/PaytableContainer";
import PaytableContent from "./paytable/PaytableContent";
import PaytableControls from "./paytable/PaytableControls";
import SymbolAnimation from './symbols/SymbolAnimation';
import ControllerJackpotPanels from './jackpot/jackpotPanels/ControllerJackpotPanels';

import PopupWin from './popups/popupWin/PopupWin';
import BackgroundContainer from './background/BackgroundContainer';
import ControllerReels from 'Engine/base/reels/ControllerReels';
import TotalWinContainer from './ui/TotalWinContainer';
import FreespinsContainer from './ui/FreespinsContainer';
import ControllerFreespinsStart from './popups/freespins/ControllerFreespinsStart';
import ControllerFreespinsEnd from './popups/freespins/ControllerFreespinsEnd';
import ControllerSlot from './slot/ControllerSlot';
import ControllerChest from './slot/ControllerChest';
import ControllerPopupsBgAnimation from './background/ControllerPopupsBgAnimation';
import ControllerJackpotPanel from './jackpot/jackpotPanels/ControllerJackpotPanel';
import BaseGameButton from './ui/BaseGameButton';
import ControllerPanel from './jackpot/jackpotGame/ControllerPanel';
import ControllerJackpotGame from './jackpot/jackpotGame/ControllerJackpotGame';
import ControllerStones from './jackpot/jackpotGame/ControllerStones';
import ControllerJackpotGamePlate from './jackpot/jackpotGame/ControllerJackpotGamePlate';
import ControllerJackpotGameField from './jackpot/jackpotGame/ControllerJackpotGameField';
import ControllerJackpotGamePanels from './jackpot/jackpotGame/ControllerJackpotGamePanels';
import ControllerStone from './jackpot/jackpotGame/ControllerStone';
import PopupWonJackpotGame from './jackpot/jackpotGame/PopupWonJackpotGame';
import PopupWonJackpot from './jackpot/jackpotGame/PopupWonJackpot';

FlashLib.registerClass('Button', BaseGameButton);
FlashLib.registerClass('CheckBox', BaseCheckBox);

FlashLib.registerClass('Main', Main);
FlashLib.registerClass('Reel', Reel);
FlashLib.registerClass('ReelsContainer', ReelsContainer);
FlashLib.registerClass('Symbol', Symbol);
// FlashLib.registerClass('WinLineAnimated', WinLineAnimated);
// FlashLib.registerClass('SlotLinesContainer', SlotLinesContainer);
FlashLib.registerClass('SettingsContainer', SettingsContainer);

FlashLib.registerClass('StartScreenContainer', StartScreenContainer);

FlashLib.registerClass('InfoPopupContainer', InfoPopupContainer);


FlashLib.registerClass('OverlayContainer', OverlayContainer);


FlashLib.registerClass('PaytableContainer', PaytableContainer);
FlashLib.registerClass('PaytableContent', PaytableContent);
FlashLib.registerClass('PaytableControls', PaytableControls);
FlashLib.registerClass('SymbolAnimation', SymbolAnimation);
FlashLib.registerClass('ControllerJackpotPanels', ControllerJackpotPanels);
FlashLib.registerClass('ControllerPanel', ControllerPanel);
FlashLib.registerClass('ControllerJackpotGame', ControllerJackpotGame);
FlashLib.registerClass('ControllerStones', ControllerStones);
FlashLib.registerClass('ControllerJackpotGamePlate', ControllerJackpotGamePlate);
FlashLib.registerClass('ControllerJackpotGameField', ControllerJackpotGameField);
FlashLib.registerClass('ControllerJackpotGamePanels', ControllerJackpotGamePanels);
FlashLib.registerClass('ControllerStone', ControllerStone);
FlashLib.registerClass('PopupWonJackpotGame', PopupWonJackpotGame);
FlashLib.registerClass('PopupWonJackpot', PopupWonJackpot);
FlashLib.registerClass('PopupWin', PopupWin);
FlashLib.registerClass('BackgroundContainer', BackgroundContainer);
FlashLib.registerClass('ControllerReels', ControllerReels);
FlashLib.registerClass('TotalWinContainer', TotalWinContainer);
FlashLib.registerClass('FreespinsContainer', FreespinsContainer);
FlashLib.registerClass('ControllerFreespinsStart', ControllerFreespinsStart);
FlashLib.registerClass('ControllerFreespinsEnd', ControllerFreespinsEnd);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('ControllerChest', ControllerChest);
FlashLib.registerClass('ControllerPopupsBgAnimation', ControllerPopupsBgAnimation);
FlashLib.registerClass('ControllerJackpotPanel', ControllerJackpotPanel);
